@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Audiowide&display=swap);
/* headers */

/* for landing page */

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(29, 29, 29);
  background-color: #e9e9e9;
  background-color: var(--background-color);

  /*-webkit-touch-callout: none; /* iOS Safari */
  /*-webkit-user-select: none; /* Safari */
  /*-khtml-user-select: none; /* Konqueror HTML */
  /*-moz-user-select: none; /* Old versions of Firefox */
  /*-ms-user-select: none; /* Internet Explorer/Edge */
  /*user-select: none; /* Non-prefixed version, currently */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
}

/* SCROLLBAR */

html {
  --scrollbarBG: #CFD8DC;
  --off-white: #d6dadd;
  --thumbBG: #b8c3c9;
  --primary-color: #e07d1d; /* #c47529; */
  --secondary-color: #25b1ef;
  --link-color: #0088ea; /*#005da0; */
  --success-green: #179e19; /* #179e19 */
  --error-red: #c42929;
  --disabled-grey: #90A4AE;
  --dark-text: #525252;
  --background-color: #e9e9e9;
}

*::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #b8c3c9 #CFD8DC;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

*::-webkit-scrollbar-track {
  background: #CFD8DC;
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: #b8c3c9;
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid #CFD8DC;
  border: 3px solid var(--scrollbarBG);
}

a {
  cursor: pointer;
  text-decoration: none;
}
